//
// overrides
//
@import "scss/Fonts";

//
// overrides
//
@import "scss/variables";

//
// plugins
//
@import "scss/plugins/select2.min.css";
@import "scss/plugins/ionicons.min.css";

//
// base
//
@import "scss/Base";
@import "scss/Custom";

//
// containers
//
@import "scss/containers/Header";
@import "scss/containers/Footer";

//
// components
//
@import "scss/components/Breadcrumb";
@import "scss/components/Contact";
@import "scss/components/FileManager";

//
// pages
//
@import "scss/pages/Home";
@import "scss/pages/Result";

// 
// Responsive
//
@import "scss/Responsive";
