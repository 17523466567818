.lead-submit-results {
    margin-top: 120px;
    text-align: center;

    .card-body {
        padding: 40px;
        h1, h2, h3 {
            color: #000;
            font-weight: 600;
            margin-bottom: 0;
        }
        .icon-box {
            display: table-cell;
            .icon {
                background-color: #000;
                border-radius: 50%;
                color: #fff;
                display: inline-block;
                width: 50px;
                height: 50px;
                padding: 10px;
            }
        }
        .content {
            display: table-cell;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            a {
                text-decoration: underline;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }
        .result-container {
            display: inline-block;
        }
    }
}